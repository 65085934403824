import React, { useEffect, useState } from "react";
import { Box, Button, CardContent } from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import EditableCard from "component/styled/EditableCard";
import CardTitleBar from "component/common/CardTitleBar";
import QRCode from "react-qr-code"
import { downloadQrCode } from "helper/qr";
import { useTranslation } from "react-i18next";
import Organization from "model/organization";
import { ContentCopy } from "@mui/icons-material";
import { route, routes } from "helper/route";
import { showSuccess } from "helper/util";

type Props = {
  organization: Organization;
};

const SectionClientSelfInvite = ({ organization }: Props) => {

  const { t } = useTranslation();

  // state that holds the qr url
  const [url, setUrl] = useState("");

  /**
  * Event handler called whenever the user download the QR code
  */
  const handleDownload = async () => {
    const svg = document.getElementById("qr-code") as HTMLElement;
    downloadQrCode(svg);
  }

  // Function to copy the invite link
  const copyInvideLink = () => {
    if (!url) {
      return;
    }
    navigator.clipboard.writeText(url);
    showSuccess(t("selfInviteLinkCopied"));
  }

  // Runs on first render
  useEffect(() => {
    setUrl(`${process.env.REACT_APP_URL}${route(routes.self_invite_client, organization.uuid)}`)
  }, [organization])

  return (
    <>
      <EditableCard sx={{ height: '100%' }}>
        <CardContent>
          <CardTitleBar title={"Client self-invite QR"}>
            <Button variant="contained" color="secondary" startIcon={<ContentCopy />} onClick={() => copyInvideLink()} sx={{ mr: 2 }}>{t("copyUrl")}</Button>
            <Button variant="contained" color="primary" onClick={handleDownload} startIcon={<DownloadIcon />}>{t("downloadQrCode")}</Button>
          </CardTitleBar>
          <Box sx={{ overflow: "hidden", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Box
              sx={{
                mt: 2,
                width: { xs: "100%", lg: "180px" },
                height: { xs: "100%", lg: "180px" },
              }}
            >
              <QRCode
                id="qr-code"
                value={url}
                size={400}
                style={{
                  height: "100%",
                  width: "100%"
                }}
              />
            </Box>
          </Box>
        </CardContent>
      </EditableCard>
    </>
  )
}

export default SectionClientSelfInvite;