import React, { useEffect, useState } from 'react';
import { Box, Table, TableBody, TableRow, TableCell, IconButton } from '@mui/material';
import { deleteTempTags, getTempTags } from 'helper/backend';
import TempTag from 'model/tempTag';
import { showError } from 'helper/util';
import { Delete, Refresh } from "@mui/icons-material";
import { formats, formatTimestamp } from 'helper/date';

const List = () => {

  const [tags, setTags] = useState<TempTag[]>([]);
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);
  const [isDeleteInProgress, setIsDeleteInProgress] = useState(false);

  useEffect(() => {
    fetchTempTags();
  }, [])

  const fetchTempTags = () => {
    setIsLoadInProgress(true);
    getTempTags()
      .then(response => {
        setTags(response.tags);
      })
      .catch(_ex => {
        showError('Unable to fetch tags');
      })
      .finally(() => {
        setIsLoadInProgress(false);
      });
  }

  const removeTempTags = () => {
    setIsDeleteInProgress(true);
    deleteTempTags()
      .then(_response => {
        setTags([]);
      })
      .catch(_ex => {
        showError('Unable to delete tags');
      })
      .finally(() => {
        setIsDeleteInProgress(false);
      });
  }

  return <React.Fragment>
    <Box sx={{ padding: 5, width: '600px', margin: '0 auto' }}>

      {/********** Page Title and Actions Toolbar **********/}
      <h3>Articole in Zona 1</h3>
      <IconButton size="large" sx={{ float: 'right', margin: '-55px 60px 0 0' }} onClick={fetchTempTags} disabled={isLoadInProgress}><Refresh /></IconButton>
      <IconButton size="large" sx={{ float: 'right', margin: '-55px 0 0 0' }} onClick={removeTempTags} disabled={isDeleteInProgress}><Delete /></IconButton>
      <Table>
        <TableBody>
          {isLoadInProgress && <TableRow>
            <TableCell colSpan={3}>Se incarca lista</TableCell>
          </TableRow>}
          {!isLoadInProgress && tags.length > 0 && tags.map((tag, idx) => {
            return <TableRow>
              <TableCell sx={{ paddingRight: 2 }}>{idx + 1}</TableCell>
              <TableCell>{tag.epc}</TableCell>
              <TableCell>{formatTimestamp(tag.updatedTs, formats.DATETIME)}</TableCell>
            </TableRow>
          })}
          {!isLoadInProgress && tags.length === 0 && <TableRow>
            <TableCell colSpan={3}>Nu sunt articole adaugate</TableCell>
          </TableRow>}
        </TableBody>
      </Table>
    </Box>
  </React.Fragment>
}

export default List;